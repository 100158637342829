import dotenv from 'dotenv'
dotenv.config()
import {Loader, LoaderOptions} from "google-maps";

const loader = new Loader(process.env.GOOGLE_MAPS_API_KEY)

loader.load().then(function (google) {
  const location = {lat: 40.846805, lng: -74.550796}
  const map = new google.maps.Map(document.getElementById('map'), {
    center: location,
    zoom: 12
  })
  const marker = new google.maps.Marker({
    position: location,
    map: map,
  });
});
